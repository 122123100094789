<template>
  <div class="mt-12">
    <div>
      <div class="video-container-wrapper">
        <div class="video-container">
          <iframe
            width="500"
            src="https://www.youtube.com/embed/eLfxHZ286V8"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>

    <div class="relative pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div class="absolute inset-0">
        <div class="bg-white h-1/3 sm:h-2/3"></div>
      </div>
      <div class="relative max-w-7xl mx-auto">
        <div class="text-center">
          <h2
            class="
              text-3xl
              tracking-tight
              font-extrabold
              text-gray-900
              sm:text-4xl
            "
          >
            Enter rooms
          </h2>
          <p class="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
            Enter one of the meeting rooms for the Coffee Break and Wrap Up.
          </p>
        </div>
        <div
          class="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none"
        >
          <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">
            <div class="flex-shrink-0">
              <img
                class="h-48 w-full object-cover"
                src="@/assets/room.jpg"
                alt=""
              />
            </div>
            <div class="flex-1 bg-white p-6 flex flex-col justify-between">
              <div class="flex-1">
                <p class="text-sm font-medium text-brand-brand">meeting</p>
                <p class="text-xl font-semibold text-gray-900">Meeting 1</p>
                <p class="mt-3 text-base text-gray-500">
                  Meet<br /><br />
                  Alke, Paul<br />
                  Fromm, Svenja<br />
                  Genuy, Martine<br />
                  Lieven, Edo<br />
                  Nielsen, Jesper<br />
                  Tommek, Stefan<br /><br />
                  in this room.
                </p>
              </div>
              <div class="mt-6 flex items-center">
                <div class="ml-3">
                  <a
                    href="/meeting/trevira-event-raum-1-dfeqreva"
                    target="_blank"
                    class="
                      p-2
                      border border-transparent
                      rounded-md
                      shadow-sm
                      text-sm
                      font-medium
                      text-white
                      bg-gray-700
                      hover:bg-gray-800
                      focus:outline-none
                      focus:ring-2 focus:ring-offset-2 focus:ring-gray-500
                    "
                    >Join</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">
            <div class="flex-shrink-0">
              <img
                class="h-48 w-full object-cover"
                src="@/assets/room.jpg"
                alt=""
              />
            </div>
            <div class="flex-1 bg-white p-6 flex flex-col justify-between">
              <div class="flex-1">
                <p class="text-sm font-medium text-brand-brand">meeting</p>
                <p class="text-xl font-semibold text-gray-900">Meeting 2</p>
                <p class="mt-3 text-base text-gray-500">
                  Meet <br /><br />Foltankova, Hana<br />
                  Hassan, Nader<br />
                  Krücken, Petra<br />
                  Schuch, Stefan<br />
                  Zhang, Jenny <br /><br />in this room.
                </p>
              </div>
              <div class="mt-6 flex items-center">
                <div class="ml-3">
                  <a
                    href="/meeting/trevira-event-raum-2-dfeqreva"
                    target="_blank"
                    class="
                      p-2
                      border border-transparent
                      rounded-md
                      shadow-sm
                      text-sm
                      font-medium
                      text-white
                      bg-gray-700
                      hover:bg-gray-800
                      focus:outline-none
                      focus:ring-2 focus:ring-offset-2 focus:ring-gray-500
                    "
                    >Join</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">
            <div class="flex-shrink-0">
              <img
                class="h-48 w-full object-cover"
                src="@/assets/room.jpg"
                alt=""
              />
            </div>
            <div class="flex-1 bg-white p-6 flex flex-col justify-between">
              <div class="flex-1">
                <p class="text-sm font-medium text-brand-brand">meeting</p>
                <p class="text-xl font-semibold text-gray-900">Meeting 3</p>
                <p class="mt-3 text-base text-gray-500">
                  Meet <br /><br />Brandi, Silvana <br />Krieger, Isabell
                  <br />Ramsey, Celeste<br />
                  Schübel, Sabine <br />Tognacca, Rossana<br />
                  Vollenbröker, Anke<br /><br />
                  in this room.
                </p>
              </div>
              <div class="mt-6 flex items-center">
                <div class="ml-3">
                  <a
                    href="/meeting/trevira-event-raum-3-dfeqreva"
                    target="_blank"
                    class="
                      p-2
                      border border-transparent
                      rounded-md
                      shadow-sm
                      text-sm
                      font-medium
                      text-white
                      bg-gray-700
                      hover:bg-gray-800
                      focus:outline-none
                      focus:ring-2 focus:ring-offset-2 focus:ring-gray-500
                    "
                    >Join</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Event",
};
</script>

<style>
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video-container-wrapper {
  max-width: 100%;
  width: 1000px;
  left: 0;
  right: 0;
  margin: auto;
}
</style>
